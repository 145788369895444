<template>
    <v-container fluid>
        <v-row style="padding-bottom: 20px;">
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                xl="2"
                lg="3"
                md="2"
                sm="2"
            >
                <h3>Sales</h3>
            </v-col>
            <v-col 
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-menu
                    ref="mnuStartDate"
                    v-model="mnuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>Start Date</label>
                        <v-text-field
                            v-model="filter.startDateFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.startDate"
                        no-title
                        @input="mnuStartDate = false"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col 
                cols="12"
                lg="3"
                md="4"
                sm="5"
            >
                <v-menu
                    ref="mnuEndDate"
                    v-model="filter.mnuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <label>End Date</label>
                        <v-text-field
                            v-model="filter.endDateFormatted"
                            prepend-inner-icon="mdi-calendar"
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            outlined
                            single-line
                            v-on="on"
                            dense
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filter.endDate"
                        no-title
                        @input="mnuEndDate = false"
                        color="primary lighten-1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">        
                <v-tabs
                    v-model="tabGraph"
                    fixed-tabs
                >
                    <v-tab key="1" @click="getRegisters">
                        By Month
                    </v-tab>
                    <v-tab key="2" @click="getRegisters">
                        By Status
                    </v-tab>
                    <v-tab key="3" @click="getRegisters">
                        By Sales Person
                    </v-tab>
                    <v-tab key="4" @click="getRegisters">
                        By Sales Person - Analytical
                    </v-tab>
                </v-tabs>
                
                <v-tabs-items v-model="tabGraph">

                    <!-- GRAPHIC BY MONTH -->
                    <v-tab-item key="1">
                        <br />

                        <v-tabs vertical fixed-tabs>
                            <v-tab @click="getRegisters">
                                Qty
                            </v-tab>
                            <v-tab @click="getRegisters">
                                Value
                            </v-tab>
                
                            <!-- BY QTY -->
                            <v-tab-item key="1">
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartMonthQtd"
                                            :chartId="'dashboardSalesMonthByQtd'"
                                            :chartData="dashboardSalesMonthByQtd"
                                        />
                                    </v-col>
                                </v-row>
                                
                            </v-tab-item>

                            <!-- BY VALUE -->
                            <v-tab-item key="2">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartMonthValue"
                                            :chartId="'dashboardSalesMonthByValue'"
                                            :chartData="dashboardSalesMonthByValue"
                                        />
                                    </v-col>
                                </v-row>

                            </v-tab-item>
                        </v-tabs>
                        
                    </v-tab-item>

                    <!-- GRAPHIC BY STATUS -->
                    <v-tab-item key="2">
                        <br />

                        <v-tabs vertical fixed-tabs>
                            <v-tab @click="getRegisters">
                                Qty
                            </v-tab>
                            <v-tab @click="getRegisters">
                                Value
                            </v-tab>

                            <!-- BY QTY -->
                            <v-tab-item key="1">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartStatusQtd"
                                            :chartId="'dashboardSalesStatusByQtd'"
                                            :chartData="dashboardSalesStatusByQtd"
                                        />
                                    </v-col>
                                </v-row>
                                
                            </v-tab-item>

                            <!-- BY VALUE -->
                            <v-tab-item key="2">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartStatusValue"
                                            :chartId="'dashboardSalesStatusByValue'"
                                            :chartData="dashboardSalesStatusByValue"
                                        />
                                    </v-col>
                                </v-row>

                            </v-tab-item>
                        </v-tabs>

                    </v-tab-item>

                    <!-- GRAPHIC BY SALES PERSON -->
                    <v-tab-item key="3">
                        <br />

                        <v-tabs vertical fixed-tabs>
                            <v-tab @click="getRegisters">
                                Qty
                            </v-tab>
                            <v-tab @click="getRegisters">
                                Value
                            </v-tab>

                            <!-- BY QTY -->
                            <v-tab-item key="1">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartSalesPersonQtd"
                                            :chartId="'dashboardSalesSalesPersonByQtd'"
                                            :chartData="dashboardSalesSalesPersonByQtd"
                                        />
                                    </v-col>
                                </v-row>
                                
                            </v-tab-item>

                            <!-- BY VALUE -->
                            <v-tab-item key="2">

                                <v-row>
                                    <v-col 
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <BarChart
                                            ref="chartSalesPersonValue"
                                            :chartId="'dashboardSalesSalesPersonByValue'"
                                            :chartData="dashboardSalesSalesPersonByValue"
                                        />
                                    </v-col>
                                </v-row>

                            </v-tab-item>
                        </v-tabs>

                    </v-tab-item>

                    <!-- ANALYTICAL BY SALES PERSON -->
                    <v-tab-item key="4">
                        <br />

                        <v-col 
                            style="text-align: right;"
                            cols="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="exportFile(URL_REPORT_SALES_PERSON_ANALYTICAL, getFilterRequest(), 'SalesPersonAnalytical.pdf');"
                                    >
                                        Export PDF
                                    </v-btn>
                                </template>
                                <span>Export Report to PDF</span>
                            </v-tooltip>
                        </v-col>

                        <v-col cols="12">
                            <v-data-table
                                :headers="headers"
                                :items="listSalesBySalesPersonAnalytical"
                                :options.sync="options"
                                :loading="loading"
                                :items-per-page="10"
                                :single-expand="true"
                                show-expand
                                no-data-text="No Record Found"
                                @click:row="showDetails"
                            >
                                <template v-slot:item.qtd="{ item }">
                                    <strong>{{ item.qtd }} </strong>                                    
                                </template>

                                <template v-slot:item.valueFormatted="{ item }">
                                    <strong>{{ item.valueFormatted }} </strong>                                    
                                </template>

                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <h3 style="margin-top: 10px;">Details</h3>
                                        <table cellspacing="10" style="width: 90%; border: none; margin: 15px;">
                                            <thead style="text-align: left;">
                                                <tr>
                                                    <th>Job Id</th>
                                                    <th>Contract Date</th>
                                                    <th>Homeowner</th>
                                                    <th>City</th>
                                                    <th>Address</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody style="padding: 10px;">
                                                <tr v-for="(itemDetail, index) in item.listDetails" :key="index">
                                                    <td>
                                                        <v-tooltip left>
                                                            <template v-slot:activator="{ on }">
                                                                <a @click="goToServiceDetails(itemDetail.jobId)" v-on="on" target="_blank">
                                                                    {{ itemDetail.jobIdFormatted }}
                                                                </a>
                                                            </template>
                                                            <span>Job Details</span>
                                                        </v-tooltip>
                                                    </td>
                                                    <td>{{ itemDetail.contractDateFormatted }}</td>
                                                    <td>{{ itemDetail.customerName }}</td>
                                                    <td>{{ itemDetail.customerCity }}</td>
                                                    <td>{{ itemDetail.customerAddress }}</td>
                                                    <td>{{ itemDetail.valueFormatted }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-tab-item>

                </v-tabs-items>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import BarChart from '@/components/Graphics/Bar.vue';
    import * as moment from 'moment';
    import { 
        URL_REPORT_SALES_PERSON_ANALYTICAL
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            BarChart
        },

        data: () => ({
            
            loading: false,
            
            tabGraph: null,

            filter: {
                startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
                endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
                startDateFormatted: moment().clone().startOf('month').format('MM/DD/YYYY'),
                endDateFormatted: moment().clone().endOf('month').format('MM/DD/YYYY'),
            },

            dashboardSalesMonthByQtd: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},
            dashboardSalesMonthByValue: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardSalesStatusByQtd: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardSalesStatusByValue: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardSalesSalesPersonByQtd: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            dashboardSalesSalesPersonByValue: {
				labels: [],
				datasets: [{
                    label: '',
                    backgroundColor: '',
                    data: []
                }]
			},

            headers: [
                { text: "Sales person", value: "userName", sortable: true },
                { text: "Qty", value: "qtd", sortable: true },
                { text: "Amount", value: "valueFormatted", sortable: true }
            ],

            options: { rowsPerPage: 10, page: 1 },
            listSalesBySalesPersonAnalytical: [],
            
            mnuStartDate: false,            
            mnuEndDate: false,

            listMonth: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],

            URL_REPORT_SALES_PERSON_ANALYTICAL: URL_REPORT_SALES_PERSON_ANALYTICAL
        }),

        watch: {
            "filter.startDate" () {
                this.filter.startDateFormatted = this.formatDate(this.filter.startDate)
                this.getRegisters();
            },

            "filter.endDate" () {
                this.filter.endDateFormatted = this.formatDate(this.filter.endDate)
                this.getRegisters();
            },
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            async getRegisters() {
                this.setGraphByMonth();
                this.setGraphByStatus();
                this.setGraphBySalesPerson();
                this.getSalesBySalesPersonAnalytical();
            },
            
            generateColor(){
                return `#${Math.floor(Math.random()*16777215).toString(16)}`;
            },

            backgroundColors(data) {
                return data.map(() => this.generateColor())
            },
            //     let yearToday = moment().clone().startOf('month').format('YYYY');

            //     this.listYear.push({id: 1, description: yearToday}); yearToday -= 1;
            //     this.listYear.push({id: 2, description: yearToday}); yearToday -= 1;

            //     this.yearSelected = this.listYear[0].description;
            // },

            getFilterRequest() {
                
                const request = {
                    startDate: this.filter.startDate,
                    endDate: this.filter.endDate
                }

                return request;
            },
            
            async setGraphByMonth() {

                const listSalesByMonth = await this.$store.dispatch("reportModule/SalesByMonth", this.getFilterRequest());
                
                this.dashboardSalesStatusByQtd.datasets = [];
                this.dashboardSalesStatusByValue.datasets = [];

                let qtdData = [];
                let valueData = [];

                listSalesByMonth.forEach(item => {
                    qtdData.push(item.qtd);
                    valueData.push(item.value);
                });

                this.dashboardSalesStatusByQtd.datasets.push({
                    label: "Qtd",
                    backgroundColor: "#018388",
                    borderColor: "#018388",
                    data: qtdData
                });

                this.dashboardSalesStatusByValue.datasets.push({
                    label: "Value",
                    backgroundColor: "#018388",
                    borderColor: "#018388",
                    data: valueData
                });

                if (this.$refs.chartMonthQtd) {
                    this.$refs.chartMonthQtd.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartMonthQtd.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesStatusByQtd.datasets;
                    this.$refs.chartMonthQtd.$refs.chart._data._chart.update("none");
                }

                if (this.$refs.chartMonthValue) {
                    this.$refs.chartMonthValue.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartMonthValue.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesStatusByValue.datasets;
                    this.$refs.chartMonthValue.$refs.chart._data._chart.update("none");
                }
            },

            async setGraphByStatus() {

                const listSalesByStatus = await this.$store.dispatch("reportModule/SalesByStatus", this.getFilterRequest());

                const listUniqueId = [...new Set(listSalesByStatus.map((item) => item.status))];
                
                this.dashboardSalesStatusByQtd.datasets = [];
                this.dashboardSalesStatusByValue.datasets = [];

                listUniqueId.forEach(item => {

                    let qtdData = [];
                    let valueData = [];
                    let statusDescription = "";
                    listSalesByStatus.filter(dash => dash.status == item).forEach (dash => {
                        qtdData.push(dash.qtd);
                        valueData.push(dash.value);
                        statusDescription = dash.statusDescription;
                    });

                    this.dashboardSalesStatusByQtd.datasets.push({
                        label: statusDescription,
                        backgroundColor: this.generateColor(),
                        data: qtdData
                    });

                    this.dashboardSalesStatusByValue.datasets.push({
                        label: statusDescription,
                        backgroundColor: this.generateColor(),
                        data: valueData
                    });
                });

                if (this.$refs.chartStatusQtd) {
                    this.$refs.chartStatusQtd.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartStatusQtd.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesStatusByQtd.datasets;
                    this.$refs.chartStatusQtd.$refs.chart._data._chart.update("none");
                }

                if (this.$refs.chartStatusValue) {
                    this.$refs.chartStatusValue.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartStatusValue.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesStatusByValue.datasets;
                    this.$refs.chartStatusValue.$refs.chart._data._chart.update("none");
                }
            },

            async setGraphBySalesPerson() {

                const listSalesBySalesPerson = await this.$store.dispatch("reportModule/SalesBySalesPerson", this.getFilterRequest());

                const listUniqueId = [...new Set(listSalesBySalesPerson.map((item) => item.idUser))];
                
                this.dashboardSalesSalesPersonByQtd.datasets = [];
                this.dashboardSalesSalesPersonByValue.datasets = [];

                listUniqueId.forEach(item => {

                    let qtdData = [];
                    let valueData = [];
                    let salesPersonName = "";
                    let graphColor = "";
                    listSalesBySalesPerson.filter(dash => dash.idUser == item).forEach (dash => {
                        qtdData.push(dash.qtd);
                        valueData.push(dash.value);
                        salesPersonName = dash.userName;
                        graphColor = dash.graphColor;
                    });

                    this.dashboardSalesSalesPersonByQtd.datasets.push({
                        label: salesPersonName,
                        backgroundColor: graphColor ?? this.generateColor(),
                        data: qtdData
                    });

                    this.dashboardSalesSalesPersonByValue.datasets.push({
                        label: salesPersonName,
                        backgroundColor: graphColor ?? this.generateColor(),
                        data: valueData
                    });
                });

                if (this.$refs.chartSalesPersonQtd) {
                    this.$refs.chartSalesPersonQtd.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartSalesPersonQtd.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesSalesPersonByQtd.datasets;
                    this.$refs.chartSalesPersonQtd.$refs.chart._data._chart.update("none");
                }

                if (this.$refs.chartSalesPersonValue) {
                    this.$refs.chartSalesPersonValue.$refs.chart._data._chart.config._config.data.labels = this.listMonth;
                    this.$refs.chartSalesPersonValue.$refs.chart._data._chart.config._config.data.datasets = this.dashboardSalesSalesPersonByValue.datasets;
                    this.$refs.chartSalesPersonValue.$refs.chart._data._chart.update("none");
                }
            },

            async getSalesBySalesPersonAnalytical() {

                this.listSalesBySalesPersonAnalytical = await this.$store.dispatch("reportModule/SalesBySalesPersonAnalytical", this.getFilterRequest());
            },

            showDetails(_, item2) {
                item2.expand(true);
            },

            goToServiceDetails(id) {
                console.log('id', id);
                this.$router.push({ name: "serviceDetails", params: {id: id} });
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style>

    table > tbody > tr {
        cursor: pointer !important;
    }

</style>